
import { computed, defineComponent, inject, onMounted, onUnmounted, ref } from 'vue';
import { EmailManagement } from '@/models/MessageTemplate';
import { EmailManagementFilter, SmsManagementFilter } from '@/models/Interfaces';
import { MessageQueueStatus, MessageQueueStatuColors, QueueMessageSource } from '@/models/Enums';
import { api } from '@/services/Api';
import q from 'q';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import EmailMessageFilter from '@/components/Modals/SmsEmailTemplateMessageFilters/EmailMessageFilter.vue';

export default defineComponent({
    components: { EmailMessageFilter },
    async setup() {
        const { t } = useI18n();
        const emitter: any = inject('emitter');
        const rows = ref<EmailManagement[]>([]);
        const filter = ref<EmailManagementFilter>(new EmailManagementFilter());
        const modalMessageEmailFilter = ref<InstanceType<typeof EmailMessageFilter>>();
        const hasNext = ref(false);
        const busy = ref(false);
        const totalRows = ref(0);
        const totalFilteredRows = ref(0);

        //Email management functions//
        const getEmailManagementHistory = async () => {
            swal.showLoading();
            const apiPromise = api.GetEmailManagementHistory(filter.value);
            await q.delay(400);
            const resp = await apiPromise;
            if (resp.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (!resp.data) {
                return;
            }
            if (rows.value && rows.value.length) {
                rows.value = [...rows.value, ...resp.data.rows];
            } else {
                rows.value = resp.data?.rows || [];
            }

            hasNext.value = resp.data.hasNext || false;

            if (filter.value.pageIndex == 0) {
                totalRows.value = resp.data.total;
                totalFilteredRows.value = resp.data.totalFiltered;
            }
            filter.value.pageIndex++;

            swal.close();
        };
        const getEmailManagementHistoryOnClick = async () => {
            filter.value.pageIndex = 0;
            rows.value = [];
            await getEmailManagementHistory();
        };
        async function resumeEmailSendNotifications(queueId: string) {
            const apiResult = await api.ResumeEmailSendNotifications(queueId);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiResult.errorMessage });
                return;
            }
            const row = rows.value.find(x => x.id == queueId);
            if (row) {
                row.status = MessageQueueStatus.InProgress;
            }
        }
        //Email management functions//

        //Filter//
        async function applyFilter(req: EmailManagementFilter) {
            filter.value = new EmailManagementFilter();
            filter.value.fromDate = req.fromDate;
            filter.value.toDate = req.toDate;
            filter.value.messageText = req.messageText;
            filter.value.fromEmail = req.fromEmail;
            filter.value.subject = req.subject;
            await getEmailManagementHistoryOnClick();
            modalMessageEmailFilter.value?.Close();
        }
        const ShowFilter = () => {
            const propFilter: EmailManagementFilter = JSON.parse(JSON.stringify(filter.value));
            modalMessageEmailFilter.value?.open(propFilter);
        };
        const hasFilter = computed<boolean>(() => {
            if (filter.value.keywords) {
                return true;
            }
            return false;
        });
        const showAll = async () => {
            swal.showLoading();
            filter.value = new EmailManagementFilter();
            rows.value = [];
            await getEmailManagementHistory();
            swal.close();
        };
        //Filter//

        //Infinity scroll
        async function ListenerGetSlotsWithNoEmployee() {
            if (hasNext.value && !busy.value) {
                await getEmailManagementHistory();
            }
        }
        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetSlotsWithNoEmployee);
            await getEmailManagementHistory();
        };
        async function onUnmountedCall() {
            emitter.off('isBottom', ListenerGetSlotsWithNoEmployee);
        }
        //Infinity scroll

        onMounted(onMountedCall);
        onUnmounted(onUnmountedCall);
        return {
            rows,
            MessageQueueStatus,
            MessageQueueStatuColors,
            filter,
            getEmailManagementHistory,
            showAll,
            hasFilter,
            ShowFilter,
            resumeEmailSendNotifications,
            getEmailManagementHistoryOnClick,
            totalRows,
            totalFilteredRows,
            modalMessageEmailFilter,
            applyFilter,
            QueueMessageSource
        };
    }
});
