
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { defineComponent } from 'vue';

import EmailManagement from '@/components/SmsEmailMessages/EmailManagement.vue';
import SmsManagement from '@/components/SmsEmailMessages/SmsManagement.vue';
import TemplateManagement from '@/components/SmsEmailMessages/TemplateManagement.vue';
import router from '@/router';

export default defineComponent({
    name: 'message-management',
    props: {
        tab: {
            type: Number,
            default: 0
        }
    },
    components: {
        TabPanel,
        TabView,
        EmailManagement,
        SmsManagement,
        TemplateManagement
    },
    async setup() {
        function onTabChange(e: any) {
            const tab = e.index;
            router.replace({ name: 'message-management', params: { tab: tab } });
        }
        return {
            onTabChange
        };
    }
});
