
import { defineComponent, ref, onMounted, computed, inject, onUnmounted } from 'vue';
import { SmsManagement } from '@/models/MessageTemplate';
import { api } from '@/services/Api';
import q from 'q';
import swal from 'sweetalert2';
import { MessageQueueStatus, QueueMessageSource, MessageQueueStatuColors } from '@/models/Enums';
import { SmsManagementFilter } from '@/models/Interfaces';
import { useI18n } from 'vue-i18n';
import SmsMessageFilter from '@/components/Modals/SmsEmailTemplateMessageFilters/SmsMessageFilter.vue';

export default defineComponent({
    components: { SmsMessageFilter },
    async setup() {
        const { t } = useI18n();
        const emitter: any = inject('emitter');
        const rows = ref<SmsManagement[]>([]);
        const filter = ref<SmsManagementFilter>(new SmsManagementFilter());
        const modalMessageSmsFilter = ref<InstanceType<typeof SmsMessageFilter>>();
        const hasNext = ref(false);
        const busy = ref(false);
        const totalRows = ref(0);
        const totalFilteredRows = ref(0);

        const getSmsManagementHistory = async () => {
            swal.showLoading();
            const apiPromise = api.GetSmsManagementHistory(filter.value);
            await q.delay(400);
            const resp = await apiPromise;
            if (resp.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (!resp.data) {
                return;
            }
            if (filter.value.pageIndex == 0) {
                totalRows.value = resp.data.total;
                totalFilteredRows.value = resp.data.totalFiltered;
            }
            if (rows.value && rows.value.length) {
                rows.value = [...rows.value, ...resp.data.rows];
            } else {
                rows.value = resp.data?.rows || [];
            }
            hasNext.value = resp.data.hasNext || false;
            filter.value.pageIndex++;

            swal.close();
        };

        const getSmsManagementHistoryOnClick = async () => {
            filter.value.pageIndex = 0;
            rows.value = [];
            await getSmsManagementHistory();
        };

        async function resumeSmsSendNotifications(queueId: string) {
            const apiResult = await api.ResumeSmsSendNotifications(queueId);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiResult.errorMessage });
                return;
            }
            const row = rows.value.find(x => x.id == queueId);
            if (row) {
                row.status = MessageQueueStatus.InProgress;
            }
        }

        //Filter//
        async function applyFilter(req: SmsManagementFilter) {
            filter.value = new SmsManagementFilter();
            filter.value.fromDate = req.fromDate;
            filter.value.toDate = req.toDate;
            filter.value.messageText = req.messageText;
            filter.value.senderName = req.senderName;
            await getSmsManagementHistoryOnClick();
            modalMessageSmsFilter.value?.Close();
        }
        const ShowFilter = () => {
            const propFilter: SmsManagementFilter = JSON.parse(JSON.stringify(filter.value));
            modalMessageSmsFilter.value?.open(propFilter);
        };
        const hasFilter = computed<boolean>(() => {
            if (filter.value.keywords) {
                return true;
            }
            return false;
        });
        const showAll = async () => {
            swal.showLoading();
            filter.value = new SmsManagementFilter();
            rows.value = [];
            await getSmsManagementHistory();
            swal.close();
        };
        //Filter//

        //Infinity scroll
        async function ListenerGetSlotsWithNoEmployee() {
            if (hasNext.value && !busy.value) {
                await getSmsManagementHistory();
            }
        }
        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetSlotsWithNoEmployee);
            await getSmsManagementHistory();
        };
        async function onUnmountedCall() {
            emitter.off('isBottom', ListenerGetSlotsWithNoEmployee);
        }
        //Infinity scroll

        onMounted(onMountedCall);
        onUnmounted(onUnmountedCall);
        return {
            rows,
            MessageQueueStatus,
            MessageQueueStatuColors,
            filter,
            getSmsManagementHistory,
            showAll,
            hasFilter,
            ShowFilter,
            resumeSmsSendNotifications,
            getSmsManagementHistoryOnClick,
            totalRows,
            totalFilteredRows,
            modalMessageSmsFilter,
            applyFilter,
            QueueMessageSource
        };
    }
});
