
import { defineComponent, onMounted, reactive, ref } from 'vue';
import swal from 'sweetalert2';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { DateRange, EmailManagementFilter } from '@/models/Interfaces';
import useOrder from '@/modules/useOrder';
import Calendar from 'primevue/calendar';
import useProduct from '@/modules/useProduct';

export default defineComponent({
    name: 'EmailMessageFilter',
    components: { Calendar, OverlayModal },
    emits: ['applyFilter'],
    async setup(props, { emit }: any) {
        const isOpen = ref(false);

        const { convertDate } = useOrder();
        const { dateRangeValidate } = useProduct();
        const filter = ref<EmailManagementFilter>(new EmailManagementFilter());
        const reservationDateRange = reactive(new DateRange());

        async function Search() {
            swal.showLoading();
            if (filter.value) {
                if (reservationDateRange.fromDate) {
                    filter.value.fromDate = convertDate(reservationDateRange.fromDate);
                }
                if (reservationDateRange.toDate) {
                    filter.value.toDate = convertDate(reservationDateRange.toDate);
                }
            }

            emit('applyFilter', filter.value);
        }

        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };

        function open(propFilter: EmailManagementFilter) {
            filter.value = propFilter;
            isOpen.value = true;
        }

        function Close() {
            isOpen.value = false;
        }

        async function ResetFilter() {
            filter.value = new EmailManagementFilter();
            reservationDateRange.fromDate = null;
            reservationDateRange.toDate = null;
            await Search();
        }

        function onMountedCall() {
            if (filter.value) {
                if (filter.value?.fromDate) {
                    reservationDateRange.fromDate = new Date(filter.value?.fromDate);
                }
                if (filter.value?.toDate) {
                    reservationDateRange.toDate = new Date(filter.value?.toDate);
                }
            }
        }

        onMounted(onMountedCall);
        return {
            filter,
            Close,
            Search,
            reservationDateRange,
            dateChanged,
            open,
            isOpen,
            ResetFilter
        };
    }
});
