
import { defineComponent, ref, onMounted, computed, inject, onUnmounted } from 'vue';
import { MessageTemplate, MessageTemplateModel } from '@/models/MessageTemplate';
import { api } from '@/services/Api';
import q from 'q';
import swal from 'sweetalert2';
import SaveSendNotificationSmsEmailTemplate from '@/components/Modals/SaveSendNotificationSmsEmailTemplate.vue';
import { MessageTemplateFilter, SlotsWithNoEmployeeFilterFromModal } from '@/models/Interfaces';
import TemplateMessageFilter from '@/components/Modals/SmsEmailTemplateMessageFilters/TemplateMessageFilter.vue';

export default defineComponent({
    name: 'MessageTemplateManagement',
    components: { SaveSendNotificationSmsEmailTemplate, TemplateMessageFilter },
    async setup() {
        const emitter: any = inject('emitter');
        const rows = ref<MessageTemplateModel[]>([]);
        const filter = ref<MessageTemplateFilter>(new MessageTemplateFilter());
        const modalSaveSendNotificationSmsEmailTemplate = ref<InstanceType<typeof SaveSendNotificationSmsEmailTemplate>>();
        const modalMessageTemplateFilter = ref<InstanceType<typeof TemplateMessageFilter>>();
        const hasNext = ref(false);
        const busy = ref(false);
        const totalRows = ref(0);
        const totalFilteredRows = ref(0);

        //Template functions//
        async function openTemplate(templateId: string) {
            swal.showLoading();
            const apiPromise = api.getMessageTemplateDetails(templateId);
            await q.delay(400);
            const resp = await apiPromise;
            if (resp.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            const template = resp.data;
            modalSaveSendNotificationSmsEmailTemplate.value?.open(template);
            swal.close();
        }
        const getTemplateManagementHistory = async () => {
            swal.showLoading();
            const apiPromise = api.getSmsEmailTemplates(filter.value);
            await q.delay(400);
            const resp = await apiPromise;
            if (resp.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data) {
                if (filter.value.pageIndex == 0) {
                    totalRows.value = resp.data.total;
                    totalFilteredRows.value = resp.data.totalFiltered;
                }
                if (rows.value && rows.value.length) {
                    rows.value = [...rows.value, ...resp.data.rows];
                } else {
                    rows.value = resp.data?.rows || [];
                }

                hasNext.value = resp.data.hasNext || false;
                filter.value.pageIndex++;
            }

            swal.close();
        };
        const getTemplatesManagementHistoryOnClick = async () => {
            filter.value.pageIndex = 0;
            rows.value = [];
            await getTemplateManagementHistory();
        };
        function createNewTemplate() {
            modalSaveSendNotificationSmsEmailTemplate.value?.open(null);
        }
        function pushTemplate(template: MessageTemplate) {
            rows.value.push(template);
            totalRows.value += 1;
            totalFilteredRows.value += 1;
        }
        function changeTemplate(template: MessageTemplate) {
            let foundedTemplate = rows.value.find(x => x.id === template.id);
            if (!foundedTemplate) {
                return;
            }

            foundedTemplate = JSON.parse(JSON.stringify(template));
        }
        //Template functions//

        //Filter//
        async function applyFilter(req: MessageTemplateFilter) {
            swal.showLoading();
            filter.value = new MessageTemplateFilter();
            filter.value.fromDate = req.fromDate;
            filter.value.toDate = req.toDate;
            filter.value.messageText = req.messageText;
            await getTemplatesManagementHistoryOnClick();
            modalMessageTemplateFilter.value?.Close();
        }
        const ShowFilter = () => {
            const propFilter: MessageTemplateFilter = JSON.parse(JSON.stringify(filter.value));
            modalMessageTemplateFilter.value?.open(propFilter);
        };
        const hasFilter = computed<boolean>(() => {
            if (filter.value.keywords) {
                return true;
            }
            return false;
        });
        const showAll = async () => {
            filter.value = new MessageTemplateFilter();
            rows.value = [];
            await getTemplateManagementHistory();
        };
        //Filter//

        //Infinity scroll
        async function ListenerGetSlotsWithNoEmployee() {
            if (hasNext.value && !busy.value) {
                await getTemplateManagementHistory();
            }
        }
        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetSlotsWithNoEmployee);
            await getTemplateManagementHistory();
        };
        async function onUnmountedCall() {
            emitter.off('isBottom', ListenerGetSlotsWithNoEmployee);
        }
        //Infinity scroll

        onMounted(onMountedCall);
        onUnmounted(onUnmountedCall);
        return {
            getTemplateManagementHistory,
            ShowFilter,
            rows,
            openTemplate,
            createNewTemplate,
            modalSaveSendNotificationSmsEmailTemplate,
            modalMessageTemplateFilter,
            changeTemplate,
            pushTemplate,
            filter,
            hasFilter,
            showAll,
            getTemplatesManagementHistoryOnClick,
            totalRows,
            totalFilteredRows,
            applyFilter
        };
    }
});
